@import "_home.scss";

.page-header{
	font-family: poppinsbold;
	background: #f0f0f0;
	padding-top:40px;
	padding-bottom:40px;
	text-align: center;
	margin-bottom: 60px;

	.breadcrumb{
		padding:0 30px 15px 30px;
		min-width: 250px;
		max-width: 100%;
		background: none;
		display: inline-flex;
		justify-content:center;
		margin-bottom: 0;
		border-radius:0;
		border-bottom: 2px solid rgba(#000,0.2);
		position: relative;

		span{
			color: #888;
			cursor: default;
		}

		&:before{
			content:'';
			position: absolute;
			left:50%;
			bottom:-2px;
			transform: translateX(-50%);
			height: 5px;
			width: 60px;
			background: #d62b26;
		}

		li + li:before{
			content:'/';
			display: inline-block;
			margin:0 10px;
		}
	}

	h1{
		font-family: poppinsbold;
		font-weight: 900;
		margin-bottom: 10px;
	}

	.detail{
		border: 1px solid currentcolor;
		border-top: none !important;
	}

	.subtitle{
		font-size: 20px;
		// margin-bottom:5px;
	}

	.detail-title{
		width: 40px;
		height:3px;
		position: relative;
		font-size: 20px;


		&:before,
		&:after{
			content:'';
			display:block;
			position:absolute;
			width: calc(50% - 2px);
			height:100%;
		}

		&:before{
			left:0;
			background: #FFF;
		}

		&:after{
			right:0;
			background: #FF002E;
		}
	}

}

svg.detail-titles{
	display:inline-block;
	vertical-align: middle;
	border:none;

	use{
		fill: currentcolor;
	}
}

.paginacao{
	margin-bottom: 60px;
	text-align:center;
	color:#333;
    justify-content: center;
    list-style: none;
    display: flex;

    li{
        a,
        span{
            display: inline-block;
            padding:0.3em 0.6em;
            border: none;
            border-radius: 4px;
            background: #008fd4;
            color: #FFF;
            padding-left: 12px;
            padding-right: 12px;
        }

        a:hover{
            background: darken(#008fd4, 10%);
            text-decoration: none;
        }

        &.active{
            pointer-events: none;
            opacity: 0.7;
        }

        & + *{
            margin-left:0.3em;
        }
    }
}

.md-form{
	position:relative;
	padding-top:1.5rem;
	padding-bottom:1rem;

	& > label{
		margin:0;
		padding:0.375rem 0.75rem;
		position:absolute;

		@include media-breakpoint-down(sm){
			top:0;
			padding:0;
		}

		@include media-breakpoint-up(md){
			top:1.5rem;
			transition:all 0.3s linear;
		}
	}

	.form-control{
		border-radius:0;

		&.form-control-lg ~ label{
			padding: 0.5rem 1rem;
			font-size:1.25rem;
		}

		&.form-control-sm ~ label{
			padding: 0.25rem 0.5rem;
			font-size:0.875rem;
		}
	}

	.hover-eff{
		width:0;
		height:2px;
		background:$green;
		transition:width 0.3s linear;
	}

	.form-control:focus ~ .hover-eff,
	&.active .hover-eff{
		width: 100%;
	}

	&.has-success{
		.hover-eff{
			background: materialColor('green','green');
		}

		label{
			color: materialColor('green','darken-2') !important;
		}
	}

	&.has-error{
		.hover-eff{
			background: materialColor('red','red');
		}

		label{
			color: materialColor('red','red') !important;
		}
	}

	&.has-warning{
		.hover-eff{
			background: materialColor('orange','darken-2');
		}

		label{
			color: materialColor('orange','darken-2') !important;
		}
	}

	@include media-breakpoint-up(md) {
		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}
	}
}

.contato-page#contato1{
	background-position: center top;


	#mapa-contato{
		.embed-responsive{
			padding-bottom: percentage(472/1920);
		}
	}

	.pdt-70{
		padding-top: 70px;
	}

	.md-form{
		.form-control{
			border-top:none;
			border-right:none;
			border-left:none;
			border-bottom-width:3px;
			border-color: #000;
		}

		.hover-eff{
			position: absolute;
			left: 0;
			bottom:1rem;
			z-index: 2;
			height:3px;
		}
	}

    .form-group{
        position: relative;
        padding-top: 1.5rem;
        padding-bottom: 1rem;

        .label{
            position: absolute;
            left: .75rem;
            top: calc(1.875rem + 1px);
            font-size: 1rem;
            transition: all .3s linear;
        }

        .form-control{
            border: none;
            border-radius: 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 200% 3px;
            background-image:
                linear-gradient(
                    to right,
                    $success 0,
                    $success 50%,
                    #000 50%
                )
            ;
            transition: background .3s linear;

            &::placeholder {
                color: transparent;
            }
        }

        &.focus .form-control,
        .form-control:focus,
        .form-control:not(:placeholder-shown) {
            background-position: left bottom;
            box-shadow: none;
        }

        &.focus,
        &.has__succcess,
        &.has__error,
        &.has__warning {
            .label{
                top: 0;
                font-size: 12px;
            }
        }

        $states: (
            'success': $success,
            'warning': $orange,
            'error'  : $danger,
            'danger' : $danger,
        );

        @each $state, $color in $states {
            &.has__#{$state} {
                .form-control{
                    background-image:
                        linear-gradient(
                            to right,
                            $color 0,
                            $color 50%,
                            #000 50%
                        )
                    ;
                    background-position: left bottom;
                }

                .message__validation,
                .label{
                    color: $color;
                }
            }
        }

        textarea.form-control{
            min-height: 8.25rem;
        }
    }

	.btn-enviar{
		background: #000;
		color: #FFF;
		padding-left:1.5rem;
		padding-right:1.5rem;
		border-radius: 2rem;

		&:hover{
			background: lighten(#000,20%);
			color: #FFF;
		}
	}

	.media-contato{
		padding: 30px 0;
		border-bottom:2px dashed rgba(#000,0.2);
		border-top:2px dashed rgba(#000,0.2);

		.media-body{
			align-self: center;
		}

		.icon{
			margin-right: 15px;
			align-self: center;
		}
	}
}

main.noticias#modelo-3{
	padding-bottom: 80px;

	.h1{
		font-weight: 400;
		margin-bottom: 30px;

		span{
			font-weight: 900;
		}

		border-bottom:1px solid rgba(#000,.3);

		&:after{
			content:'';
			display: block;
			width: 100px;
			height: 3px;
			background: #046C9D;
			margin-top: 7px;
			transform: translateY(4px);
		}
	}

	.noticia-text{
		margin-bottom: 20px;

		.tag{
			color: #046C9D;
		}

		.titulo{
			font-size: 30px;
			line-height: 1.2;
			max-height: (1em * 3 * 1.2);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
			font-weight: 900;
			color: #444;
		}

		.descricao{
			font-size: 16px;
			line-height: 1.2;
			max-height: (1em * 1.2 * 3);
			color: #777;

			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
		}

		a:hover{
			color: #000;

			.descricao{
				color: #000;
			}
		}

		.footer{
			display: flex;
			flex-wrap:wrap;
			align-items: center;
			padding:10px 0;
			border-top:1px solid rgba(#000,0.2);
			border-bottom:1px solid rgba(#000,0.3);

			.redes-sociais{
				flex-grow:1;
				color: #777;

				a{
					display: inline-block;
					margin:0 10px;

					&:hover{
						text-decoration: none;
					}
				}
			}

			.postado{
				font-size: 12px;
				color: #777;
			}
		}
	}

	.noticia-covered{
		width: 350px;
		max-width: 100%;
		position: relative;

		.tag{
			display: inline-block;
			padding:5px 10px;
			background: #d62b26;
			margin-bottom: 10px;
		}

		.caption-noticia{
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			bottom: 0;
			z-index: 5;
			padding:10px;
			background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,.8) 100%);
			color: #FFF;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		.titulo{
			line-height: 1.2;
			font-weight:900;
			font-size: 25px;
			max-height: calc(1em * 1.2 * 3);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}

	.noticia-h{
		margin-bottom: 20px;
	}


	@include media-breakpoint-down(md) {
		.noticia-text{
			width: 350px;
			max-width: 100%;
		}

		.noticia-covered,
		.noticia-text{
			margin-right:auto;
			margin-left:auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.noticia-h:nth-child(even){
			.image-area{
				order: 1;
			}
			.text-area{
				order: 2;
			}
		}
		.noticia-h:nth-child(odd){
			.image-area{
				order: 2;
			}
			.text-area{
				order: 1;
			}
		}
	}

	.mais-lidas{
		margin-bottom: 30px;
		border-radius: 0;
		border:none;

		.card-header{
			color: #222;
			border-radius: 0;
			font-weight: 300;
			font-size: 25px;
			border: none;
			padding-bottom:0;
			border-left:5px solid #046C9D;
			background: none;

			span{
				font-weight: 900;
			}
		}

		.card-body{
			border-left:5px solid #046C9D;
		}

		.card-footer{
			padding-left:0;
			padding-right:0;
			background: none;

			.fa-angle-right-double{
				&:after,
				&:before{
					content: fa-content($fa-var-angle-right);
				}
			}

			.btn{
				background: #333333;
				color:#FFF;
				border-radius: 0;
				text-align:left;
				padding: 15px 15px;

				&:hover{
					background: darken(#333,10%)
				}
			}
		}

		a:hover{
			text-decoration: none;

			.media-body{
				color: #046C9D;
				text-decoration: underline;
			}
		}

		.media{
			padding: 15px 0;
			color: #777;
			border-top: 1px solid rgba(#000,.2);

			h3{
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.4;
				max-height: (1em * 1.4 * 4);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
			}

			.media-body{
				padding-bottom: 15px;
			}

			a:hover{
				color: #046C9D;
			}
		}

		.publicado{
			font-size: 12px;
			margin-bottom: 5px;
			line-height: 1.3;

			&:before{
				content:'';
				display: inline-block;
				height: 4px;
				width: 4px;
				border-radius: 100%;
				background: #046C9D;
				margin-right: 5px;
			}
		}

		.rank{
			min-width: 55px;
			font-size: 45px;
			padding-right:10px;
			padding-top: 7px;
			font-weight:900;
			color: #333333;
			transition:color 0.3s linear;
		}

		a:hover .rank{
			color: #C2D3E0
		}
	}

	// .paginacao{
	// 	display: flex;
	// 	justify-content: center;
	// 	flex-wrap: wrap;

	// 	a,
	// 	span{
	// 		margin: 0;
	// 		border:1px solid rgba(#000,0.2);
	// 		background: #FFF;
	// 	}

	// 	.active{
	// 		background: #046C9D;
	// 		color: #FFF;
	// 	}

	// 	*:not(.active):hover{
	// 		background: #eee;
	// 	}
	// }

	.btn-load-more{
		background: #046C9D;
		color:#FFF;

		&:hover{
			background: darken(#046C9D, 10%);
			color: #FFF;
		}
	}

	.noticia-detalhes{
		.header{
			margin-bottom: 40px;
			border-bottom: 3px solid #DADADA;
		}

		h1{
			border: none;
			padding-bottom: 0;
			font-weight: 900;
			font-size: 30px;
			color:#333;
			margin-bottom: 20px;

			&:after{
				display: none;
			}
		}

		.subtitulo{
			font-size: 15px;
			color: #909090;
			margin-top:0;
			margin-bottom: 20px;
			font-weight: 400;
		}

		.header-detail{
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid #046C9D;
			padding: 10px;

			.autor{
				flex-grow: 1;

				span{
					color: #046C9D;
				}

				@include media-breakpoint-up(md) {
					order: 2;
					text-align: right;
				}
			}

		}

		.redes-sociais{

			a{
				display: inline-flex;
				width: 2em;
				height: 2em;
				align-items:center;
				justify-content:center;
				border-radius: 100%;

				& + a{
					margin-left: 5px;
				}

				&:hover{
					text-decoration: none;
				}

				&.fa-facebook,
				&.fa-facebook-f{
					background: materialColor('blue','darken-2');
					color: #FFF;
				}

				&.fa-twitter{
					background: materialColor('blue','blue');
					color: #FFF;
				}

				&.fa-instagram{
					background: materialColor('purple','purple');
					background: linear-gradient(45deg, materialColor('purple','purple') 0%,materialColor('orange','orange') 100%);
					color: #FFF;
				}
			}

			@include media-breakpoint-up(md) {
				order: 1;
			}
		}

		img{
			max-width: 100%;
			height: auto !important;
		}

		.body{
			padding-bottom: 20px;
		}

		.footer{
			padding-top:20px;
			border-top:1px dashed rgba(#000,0.3);
		}
	}

	#album-noticias {
		display: flex;

		.prev,
		.next {
			padding: 15px 10px;
			display: flex;
			align-items: center;
			background: #046C9D;
			color: #FFF;

			&:hover{
				text-decoration: none;
			}
		}

		.prev{
			order: 1;
		}

		.carousel-inner{
			order: 2;
		}

		.next{
			order: 3;
		}
	}

    .imagens__owl-noticias.owl-carousel{
        padding: 0px 30px;
        position: relative;

        .owl-nav{
            button.owl-prev,
            button.owl-next{
                width: 30px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content:center;
                background-color: #046C9D;
                color: #fff;
                font-size: 18px;
                line-height: 18px;
                top: 0px;
                position: absolute;
                border: none;
				transform: unset !important;
            }
            button.owl-prev{
                left: 0px;
            }
            button.owl-next{
                right: 0px;
            }
        }
    }

	.redes-sociais h5{
		color: #777;
		font-weight: 400;
	}

	h3.h5{
		margin-bottom: 20px;
		color: #046C9D;
	}
}

#perguntas-2 {
	padding-bottom: 80px;

	h2{
		font-size: 20px;
		font-weight: 300;

		&:after{
			content:'';
			display: block;
			width: 120px;
			height:5px;
			background: #008fd4;
			margin-top:10px;
		}
	}

	.box-perguntas{
		padding: 90px 40px 30px 40px;
		background: #eeeeee;
	}

	.aprentar-pergunta{
		width: 426px;
		max-width: calc(100% - 15px);
		margin-bottom: -90px;
		position: relative;
		z-index: 2;
		margin-left: auto;
		margin-right: 15px;

		&:before{
			content:'';
			display: block;
			padding-bottom: percentage(264/426);
			background: url(../images/apresentar-pergunta.png);
		}
	}

	.box-perguntas-content{
		max-height: 500px;
		overflow: auto;
		padding-right: 20px;

		&::-webkit-scrollbar {
			width: 7px;
		  	border-radius: 10px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
		  	background: #FFF;
		  	border-radius: 10px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
		  	background: #008fd4;
		  	border-radius:10px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
		  	background: darken(#008fd4, 10%);
		}
	}

	.pergunta-faq{
		padding-left: 20px;

		.pergunta{
			color: #008fd4;
			position: relative;
			font-size: 20px;

			&:before{
				content: fa-content($fa-var-angle-right);
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				display: block;
				width:20px;
				margin-left: -20px;
				float: left;
			}
		}
	}

	.pergunta-faq + .pergunta-faq{
		margin-top: 30px;
	}
}

.galeria-fotos#modelo-1{
	padding-bottom: 80px;

	.album-fotografia {
		width: 350px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		position: relative;
		overflow: hidden;

		.album-caption {
			padding: 10px;
			background: #008fd4;
			color: #FFF;

			@include media-breakpoint-up (lg) {
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 0;
				transform: translateY(100%);
				transition: all 0.3s linear;
			}

			.nome{
				font-weight: 900;
				margin-bottom: 5px;
				font-size: 18px;
				line-height: 1.3;
				max-height: (1em * 1.3 * 2);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			.description{
				line-height: 1.4;
				max-height: (1em * 1.4 * 2);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}
		}

		@include media-breakpoint-up(lg) {
			&:hover .album-caption{
				transform: translateY(0);
			}
		}
	}

	.carregar-mais{
		padding-top: 60px;
	}

	.btn-carregar-mais{
		background: #008fd4;
		color:#FFF;
		border-bottom-width:5px;
		border-color: rgba(#000,0.2);
		font-weight: 900;
	}

}

.album.galeria__album{
	width: 100%;
	height: 100%;
	top:0;
	left:0;
	position: fixed;
	z-index: 1024;
	background: rgba(#000,0.8);
	display: none;

	&.show{
		display: block;
		animation: fadeIn 0.6s linear;
	}

	.content{
		width: calc(100vh - 200px);
		max-width: calc(100vw - 100px);
		position: relative;
		top:50%;
		transform: translateY(-50%);
		margin-left: auto;
		margin-right: auto;
		z-index: 5;
	}

	.fechar{
		color:#FFF;
	}

	.header{
		text-align: right;
		min-height: 40px;
	}

	.carousel{
		max-width: calc(100vh - 200px);
	}

	.wrapper-carousel{
		position: relative;
	}

	.prev,
	.next{
		width:30px;
		height: 30px;
		display: inline-flex;
		align-items:center;
		justify-content:center;
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background: #FFF;
		border-radius: 100%;

		&:hover{
			text-decoration: none;
		}
	}

	.prev{
		right: calc(100% + 10px);
	}

	.next{
		left: calc(100% + 10px);
	}

	.carousel-indicators{
		position: static;
		bottom: 0;
		width: 100%;
		margin-right: 0;
		margin-left: 0;
		flex-wrap: nowrap;
		overflow: auto;
		min-height: 130px;
		justify-content: flex-start;

		li{
			width: 100px;
			min-width: 100px;
			height: auto;
			text-indent: 0;
			background: none;
		}
	}
}

#produtos-mod-6{

	.produto{
		max-width: 100%;
		width: 466px;
		text-align:center;

		a:hover{
			text-decoration: none;
			color: #000;

			.content:before,
			.content:after{
				opacity: 1;
			}
		}

		.content{
			position:relative;

			&:before,
			&:after{
				content:'';
				display: block;
				position:absolute;
				opacity: 0;
				transition: opacity 0.3s linear;
			}

			&:before{
				width: 100%;
				height:100%;
				background: rgba(#000,0.4);
				left:0;
				top:0;
				z-index:1;
			}

			&:after{
				left:16px;
				top:16px;

				width: calc(100% - 32px);
				height: calc(100% - 32px);

				border:10px solid #008fd4;
				z-index: 2;
				background: url(#{$imgs}/efeito-produto-modelo-3.png) center center no-repeat;
			}
		}

		.foto{
			margin:0;
		}

		.nome{
			@include gradient-y(#fff, rgba(#000,0.1));
			padding:10px;
		}

		.btn-area{
			padding: 10px;
		}

		.btn{
			background: transparent;
			color: #000;
			border-color:#000;
			border-radius:0;
			border-width:2px;

			&:hover{
				background: #008fd4;
				color: #fff;
				border-color: #008fd4;
			}
		}
	}

	@include media-breakpoint-up(lg){

		.row-produtos{
			margin-left: -10px;
			margin-right: -10px;

			.col-lg{
				max-width: 33.333333%;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	@include media-breakpoint-between(sm,md){

		.row-produtos{
			margin-left: -10px;
			margin-right: -10px;

			.col-lg{
				max-width: 50%;
				padding-left:10px;
				padding-right:10px;
			}
		}
	}

	// .paginacao{

	// 	& > *{
	// 		$color: #000;

	// 		color: $color;


	// 		&.active{
	// 			background: $color;
	// 			color: #fff;
	// 			border-color: $color;
	// 		}
	// 	}
	// }

	&.detalhes{

		padding-bottom: 60px;

		.page-header{
			margin-bottom: 30px;
		}
	}

	.box{
		padding: 20px;
		margin-bottom:30px;

		&.box-white{
			background: #FFF;
		}
	}

	.subtitle-produtos{
		font-family: 'poppinsmedium';
	}

	.midias-sociais{
		font-size: 20px;

		a{
			display: inline-block;
			margin: 0 0.5em;
		}
	}

    .bar__categorias{
        box-shadow: 0 0 10px rgba(#000,.4);
        margin-bottom: 40px;
        position: sticky;
        top: 15px;

        .bar__categorias__header{
            padding: 15px;
            text-align: center;
            font-weight: 900;
            font-size: 18px;
            background: #008fd4;
            color: #FFF;
        }

        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
        }

        a{
            display: block;
            padding: 10px 15px;
            flex-grow: 1;
        }

        .btn{
            padding: 10px 15px;
            border-radius: 0;
            box-shadow: none !important;

            .fas{
                transform: rotate(var(--rotate, 0deg));
                transition: transform .3s linear;
            }

            &[aria-expanded="true"] {
                --rotate: -180deg;
            }
        }
    }

    .categoria__header{
        display: flex;
    }

    .categoria + .categoria{
        border-top: 1px solid #CCC;
    }

    .subcategorias{
        border-top: 1px solid #CCCC;
        background: #DDD;

        li + li{
            border-top: 1px solid rgba(#000,.1);
        }
    }
}
.politica{
	padding-bottom: 60px;
}
#imagens-produto{

	@include media-breakpoint-up(lg){

		.image-g{
			margin-bottom: 15px;
		}
	}
}

#modelo-3.empresa{
	.title{
		color: #008fd4;
		font-weight: 900;
	}

	#carousel-empresa{
		width:850px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		.carousel-wrapper{
			position: relative;
			margin-bottom:10px;
		}

		.next,
		.prev{
			position: absolute;
			width: 40px;
			height: 40px;
			top: calc(50% - 20px);
			background: #FFF;
			border-radius: 100%;
			display: flex;
			align-items:center;
			justify-content:center;
			border:1px solid rgba(#000,0.2);
			box-shadow: 0 0 5px rgba(#000,0.4);
			text-decoration: none;

			&:hover{
				background: #eee;
			}
		}

		.next{
			right:15px;
		}

		.prev{
			left:15px;
		}

		.carousel-indicators{
			position: static;
			margin-bottom: 0;
			justify-content: flex-start;
			width:100%;
			margin-left:0;
			margin-right:0;
			overflow: auto;

			li{
				width: calc(50% - 15px);
				min-width: calc(50% - 15px);
				height: auto;
				text-indent: 0;
				background: #FFF;
				margin:0;

				& + li{
					margin-left: 30px;
				}
			}
		}
	}

	hr{
		border-color: rgba(#000,0.2);
		border-width: 2px;
		margin-bottom: 50px;
	}

	.missao-visao-valores{
		text-align:center;

		.lazyimage,
		img{
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 15px;
		}

		.titulo{
			font-size: 20px;
			margin-bottom: 10px;

			span{
				font-weight: 900;
				color: #d62b26;
			}
		}
	}

	.depoimento{
		text-align: center;
		padding-top: 40px;
		padding-bottom: 40px;
		background: #008fd4;

		.fas.fa-quote-left{
			font-size: 50px;
			margin-bottom: 15px;
		}

		h2{
			font-weight: 300;
			font-style: italic;
		}

		.autor{
			margin-top:30px;
		}

	}

	.autor{
		.avatar{
			width: 80px;
			height: 80px;
			border-radius: 100%;
			border:2px solid #FFF;
			overflow: hidden;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}

		.nome{
			font-weight: bold;
		}

		.cargo{
			font-size: 13px;
		}

	}
}

.modal__custom__obrigado{
	.modal-dialog{
		max-width: 540px;
		width: 100%;

		.modal-content,
		.modal-body,
		.modal-header{
			border: none;
			background-color: transparent;
			padding: 0px;
		}
		.modal-content{
			padding: 70px 25px 60px;
			background-color: #008fd4;
			color: #fff;
			text-align: center;
			border-radius: 15px;
			position: relative;
		}
		.modal-header{
			padding: 5px 0px;
			display: flex;
			justify-content: flex-end;

			button{
				padding: 0px;
				background-color: transparent;
				color: #fff;
				border: none;
				font-family: 'poppinsbold';
				position: absolute;
				top: 20px;
				right: 20px;
			}
		}
		.modal-body{


			.modal__icone{
				margin-bottom: 15px;
			}
			.modal__titulo{
				font-family: 'poppinsmedium';
				font-size: 35px;
				line-height: 38px;
				text-align: center;
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 25px;
				max-width: 440px;
				letter-spacing: -0.5px;

				@include media-breakpoint-down (md) {
					font-size: 26px;
					line-height: 30px;
				}
			}
		}

	}
	.modal__center__btn{
		display: flex;
		justify-content: center;
		.ok__btn{
			display: flex;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border-radius: 25px;

      border: none;

      font-family: 'poppinssemibold';
      font-size: 15px;
      color: #008fd4;
      line-height: 1.2;

      margin: 0px 4px;
			width: 100px;
			height: 50px;

			&:hover{
				color: #008fd4;
        		text-decoration: none;
			}
		}
	}
}

.modal__interna{
	background-color: #008fd4;
	color: #fff;
	text-align: center;
	padding: 250px 0px;
	.modal__icone{
		margin-bottom: 15px;
	}
	.modal__titulo{
		font-family: 'poppinsmedium';
		font-size: 35px;
		line-height: 38px;
		text-align: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;
		max-width: 440px;
		letter-spacing: -0.5px;

		@include media-breakpoint-down (md) {
			font-size: 26px;
			line-height: 30px;
		}
	}
	.modal__center__btn{
		display: flex;
		justify-content: center;
		.ok__btn{
			display: flex;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border-radius: 25px;

      border: none;

      font-family: 'poppinssemibold';
      font-size: 15px;
      color: #008fd4;
      line-height: 1.2;

      margin: 0px 4px;
			width: 100px;
			height: 50px;

			&:hover{
				color: #008fd4;
        text-decoration: none;
			}
		}
	}
}
