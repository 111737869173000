.rodape{
	background-color: #ededed;
}
.rodape__conteudo{
	padding: 22px 0px 50px 0px;
}
.rodape__grid{
	@include media-breakpoint-up (lg) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		column-gap: 30px;

		.rodape__titulo{
			padding-top: 95px;
		}

		.col__1{
			width: 100%;
			max-width: 380px;
		}
		.col__2{
			width: 100%;
			max-width: 250px;
		}
		.col__3{
			width: 100%;
			max-width: 160px;
		}
		.col__4{
			width: 100%;
		}
	}
}
.rodape__titulo{
	margin-bottom: 20px;
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 20px;
}
.rodape__logo{
	margin-bottom: 6px;
}
.rodape__desc{
	text-align: justify;
	margin-bottom: 15px;
	font-size: 13px;
	line-height: 16px;
}
.sociais__titulo{
	font-family: poppinssemibold;
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 5px;
}

.sociais__rodape{
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 5px;

	a{
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 32px;
		background-color: #008fd4;
		color: #fff;
		transition: all 0.3s linear;
		border-radius: 50%;
		font-size: 18px;
		line-height: 18px;

		&:hover{
			background-color: #d62b26;
			color: #fff;
			text-decoration: none;
		}
	}
}
.sociais__segura{
	display: flex;
	align-items: center;
	justify-content: space-between;

	.sociais__btn{
		min-width: 160px;
	}
}
.rodape__contato__item{
	margin-bottom: 19px;
	.rodape__contato__titulo{
		font-family: poppinssemibold;
		font-size: 14px;
		line-height: 18px;
		color: #292926;
		margin-bottom: 3px;
	}
	.rodape__contato__tels{
		font-family: poppinslight;
		display: flex;
		align-items: center;
	}
}

.rodape__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;

	li{
		margin-bottom: 12px;
	}
}

.news__desc{
	margin-bottom: 20px;
}
.news__input{
	width: 100%;
	display: block;
	border: none;
	padding: 13px 20px;
	font-size: 13px;

	&::placeholder{
		color: #292926;
	}
}

.news__form{
	.form-group{
		margin-bottom: 10px;
	}
}
.news__btn{
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	background-color: #008fd4;
	color: #fff;
	padding: 12px 20px;
	transition: all 0.3s linear;
	border: none;

	&:hover{
		background-color: #d62b26;
		color: #fff;
		text-decoration: none;
	}
}
.rodape__contato__grid{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 25px;
}

.rodape__creditos{
	padding: 30px 0px;
	border-top: 1px solid #d1d1d1;
	font-size: 12px;
	line-height: 15px;

	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.gv8__box{
	display: flex;
	align-items: center;
	column-gap: 5px;
}
@include media-breakpoint-down (md) {
	.rodape__grid{
		text-align: center;
	}
	.sociais__titulo{
		margin-bottom: 10px;
	}
	.sociais__segura{
		flex-direction: column;
		row-gap: 10px;
	}
	.rodape__sociais{
		margin-bottom: 40px;
	}
	.rodape__contato__tels{
		justify-content: center;
	}
	.rodape__contato__grid{
		flex-direction: column;
		align-items: center;
		display: flex;
	}
	.rodape__contatos{
		margin-bottom: 40px;
	}
	.rodape__menu{
		margin-bottom: 40px;
		li{
			margin-bottom: 5px;
		}
	}
	.rodape__creditos{
		.container{
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}
}
.main__logo{
	padding: 10px 0px;
}
